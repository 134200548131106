export default {
    tableFields:   [
        {
            name:      '__component:badge-column',
            title:     '',
            dataClass: 'text-center',
            width:     '4%'
        },
        {
            name:      'name',
            sortField: 'name',
            title:'Nombre',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            
        },
        {
            name:      'description',

            sortField: 'description',
            title:'Descripcion',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            
        },     
      
        {
            name:       '__slot:active',
            title:      'Active',
            title:'Estatus',
            titleClass: "center aligned",
            dataClass:  "center aligned",
           
        },
        {
            name:       '__slot:actions',
            title:      'Acciones',            
            titleClass: "center aligned",
            dataClass:  "center aligned",
           
        },
    ],
    sortFunctions: {
        'name': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
    }
}
